export interface Roles {
    client: boolean;
    sales?: boolean;
    assistant?: boolean;
    manager?: boolean;
    admin?: boolean;
}

export class User {
    name:     string;
    surname:  string;
    email:    string;
    photoURL: string;
    avatar:   string;
    roles:    Roles;

    constructor(authData, userData) {
        this.email    = authData.email;
        this.photoURL = authData.photoURL;
        this.roles    = { client: true };
        if (userData) {
            this.name = userData.name;
            this.surname = userData.surname;
            this.avatar = userData.avatar;
            this.roles = userData.roles;
        }
    }
}

