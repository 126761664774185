import { PopoverController, NavParams } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { SortChoice, SORT_CHOICES } from '../../core/sort-choice';

@Component({
  selector: 'app-sort-popover',
  templateUrl: './sort-popover.component.html',
  styleUrls: ['./sort-popover.component.scss']
})
export class SortPopoverComponent implements OnInit {
  sortOrder: string;
  promoOnTop: boolean;
  popoverCtrl: PopoverController;
  sortChoices: SortChoice[];

  constructor(private navParams: NavParams) {
    this.sortOrder = navParams.get('sortOrder');
    this.popoverCtrl = navParams.get('popoverController');
    this.promoOnTop = navParams.get('promoOnTop') || false;
    this.sortChoices = SORT_CHOICES;
  }

  ngOnInit() {
  }

  itemClick(item) {
    this.sortOrder = item.value;
    this.popoverCtrl.dismiss(item.value, 'order');
  }

  promoToggle() {
    this.promoOnTop = !this.promoOnTop;
    this.popoverCtrl.dismiss(this.promoOnTop, 'promo');
  }

  getSortOrder() {
    return this.sortOrder;
  }
}
