export class SortChoice {
    title: string;
    value: number;
}

export const SORT_AZ = 1;
export const SORT_ZA = -1;
export const SORT_LH = 2;
export const SORT_HL = -2;

export const SORT_CHOICES: SortChoice[] = [
    { title: 'A-Z', value: SORT_AZ },
    { title: 'Z-A', value: SORT_ZA },
    { title: 'Lowest to Highest Price', value: SORT_LH },
    { title: 'Highest to Lowest Price', value: SORT_HL }
];
