import { Roles } from './../core/user';
import { Platform, LoadingController, AlertController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';

import * as firebase from 'firebase';

import { User } from '../core/user';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  authenticationState = new BehaviorSubject(false);
  user: BehaviorSubject<User> = new BehaviorSubject(null);
  db: firebase.database.Database;

  constructor(
    private storage: Storage,
    private platform: Platform,
    public loadingController: LoadingController,
    public alertController: AlertController) {
  }

  async presentLoading(message) {
    const loading = await this.loadingController.create({
      translucent: true,
      message: message
    });
    loading.present();
    return loading;
  }

  async presentAlert(message) {
    const alert = await this.alertController.create({
      header: 'Authentication',
      message: message,
      buttons: ['OK']
    });
    return await alert.present();
  }

  private updateUser(authData, userData) {
    const crtUserData = new User(authData, userData);
    const ref = this.db.ref('users/' + authData.uid);
    ref.once('value', snap => {
      const user = snap.val();
      if (!user || !user.role) {
        ref.update(crtUserData).catch(err => {
          console.log(err);
        });
      }
    });
  }

  initialize() {
    this.db = firebase.database();
    this.authenticationState.next(firebase.auth().currentUser != null);
    firebase.auth().onAuthStateChanged( user => {
      if (user) {
        this.db.ref('users/' + user.uid).on('value', snap => {
          this.user.next(snap.val());
          this.authenticationState.next(true);
          console.log('User logged in.');
        });
        console.log('User data being retrieved.');
      } else {
        console.log('User is not logged in.');
        this.authenticationState.next(false);
        this.user.next(null);
      }
    });
  }

  async doRegister(value) {
    const loading = await this.presentLoading('Trying to register ...');
    return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(value.email, value.password)
      .then(res => {
        loading.dismiss();
        this.updateUser(res.user, value);
        resolve(res);
      }, err => {
        loading.dismiss();
        console.error('createUserWithEmailAndPassword', err.message);
        this.presentAlert('Unable to complete registration!');
        reject(err);
      }).catch(error => {
        loading.dismiss();
        console.error('createUserWithEmailAndPassword', error.message);
        reject(error);
      });
    });
   }

  async doLogin(value) {
    const loading = await this.presentLoading('Trying to login ...');
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(value.email, value.password)
      .then(res => {
        loading.dismiss();
        this.updateUser(res.user, null);
        resolve(res);
      }, err => {
        loading.dismiss();
        console.error('Invalid email and/or password!');
        this.presentAlert('Invalid email and/or password!');
        reject(err);
      }).catch(error => {
        loading.dismiss();
        console.error('signInWithEmailAndPassword', error.message);
        reject(error);
      });
    });
   }

   async doAnonymousLogin() {
    const loading = await this.presentLoading('Trying to login ...');
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInAnonymously()
      .then(res => {
        loading.dismiss();
        resolve(res);
      }, err => {
        loading.dismiss();
        this.presentAlert('Unable to login as a visitor!');
        reject(err);
      }).catch(error => {
        loading.dismiss();
        console.error('signInAnonymously', error.message);
        reject(error);
      });
    });
   }

   doLogout() {
    return new Promise((resolve, reject) => {
      if (firebase.auth().currentUser) {
        firebase.auth().signOut();
        resolve();
      } else {
        reject();
      }
    });
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

  getAvatar() {
    if (this.user.value) {
      if (this.user.value.avatar) {
        return this.user.value.avatar;
      }
    }
    return null;
  }

  getUsername() {
    if (this.user.value) {
      if (this.user.value.name && this.user.value.surname) {
        return this.user.value.name + ' ' + this.user.value.surname;
      }
      if (this.user.value.email) {
        return this.user.value.email;
      }
    }
    return 'anonymous';
  }

  getCurrentUser() {
    return this.user.value;
  }

  getUserRoles(): Roles {
    if (this.isAuthenticated()) {
      if (this.user.value) {
        return this.user.value.roles;
      }
      return { client: true };
    }
    return { client: false };
  }
}
