import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import * as firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyCXoedD2WF65aHn5rhlLtxpBY01VL56La4',
  authDomain: 'digitag-app.firebaseapp.com',
  databaseURL: 'https://digitag-app.firebaseio.com',
  projectId: 'digitag-app',
  storageBucket: 'digitag-app.appspot.com',
  messagingSenderId: '370436673736'
};

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  appPages = [
    {
      title: 'Dashboard',
      url: '/members/dashboard',
      icon: 'home'
    },
    {
      title: 'Admin',
      url: '/members/admin',
      icon: 'list'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public authService: AuthenticationService,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    firebase.initializeApp(firebaseConfig);

    this.authService.initialize();

    this.authService.authenticationState.subscribe(state => {
      if (state) {
        if (this.authService.getUserRoles().admin) {
          this.router.navigate(['members', 'admin']);
        } else if (this.authService.getUserRoles().manager) {
          this.router.navigate(['members', 'admin2']);
        } else if (this.authService.getUserRoles().assistant) {
          this.router.navigate(['members', 'admin2']);
        } else if (this.authService.getUserRoles().sales) {
          this.router.navigate(['members', 'dashboard']);
        } else {
          this.router.navigate(['members']);
        }
      } else {
        this.router.navigate(['login']);
      }
    });
  }

  logout() {
    setTimeout(() => {
      this.authService.doLogout();
    }, 400);
  }
}
